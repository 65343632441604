<!--
 * @Author: Jacob.Zhang
 * @Description: 客户端嵌入页
-->
<template>
    <div class="complete-container">
        <router-view />
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
    name: "MiddleInfo",
    created() {
        this.getToken()
    },
    methods: {
        ...mapMutations('gifted', ['updateUser']),
        getToken() {
            localStorage.setItem("token", this.$route.query.token);
            this.$store.commit("setToken")
        },
    },
}
</script>

<style lang="less" scoped>
.complete-container {
    width: 960px;
    // margin: 20px 0;
    color: #606266;
    font-size: 14px;
}
</style>
